










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'Toggler' })
export default class Toggler extends Vue {
  @Prop(Boolean)
  value!: boolean
}
