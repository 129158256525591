


























import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseIcon from '@/components/base/BaseIcon.vue'
import {namespace} from "vuex-class";
import {NewsModel} from "@/core/models/NewsModel";
const NewsStore = namespace("NewsStore");

@Component({
  name: 'Menu',
  components: { BaseIcon },
})
export default class Menu extends Vue {
  @Prop(Boolean)
  small!: boolean

  @NewsStore.Getter
  public newsList!: NewsModel[];

  @NewsStore.Action
  private getNews!: () => Promise<void>;

  created() {
    this.getNews()
  }
  get newsRedCircle() {
    return this.newsList ? this.newsList.some((e) => e.read_at === null) : false
  }

  get menus() {
    return [
      { id: 1, title: 'Мои помещения', path: '/my-rooms', icon: 'my-premises' },
      { id: 2, title: 'Мои адреса', path: '/my-locations', icon: 'maps' },
      { id: 3, title: 'Статистика', path: '/statistics', icon: 'statistics' },
      // { id: 3, title: 'Спец. цены', path: '/special-price', icon: 'special-price' },
      // { id: 4, title: 'Календарь', path: '/calendar', icon: 'calendar' },
      // { id: 5, title: 'Клиенты', path: '/clients', icon: 'clients' },
      { id: 10, title: 'Новости', path: '/news', icon: 'calendar', red_circle: this.newsRedCircle },
      { id: 6, title: 'Заявки и звонки', path: '/events', icon: 'orders' },
      { id: 7, title: 'Профиль и оплата', path: '/profile', icon: 'profile' },
      { id: 8, title: 'Премиум тарифы', path: '/premium', icon: 'premium' },
    ]
  }
}
