var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.b(null, { small: _vm.isSmall })},[_c('div',{class:_vm.b('top', { small: _vm.isSmall })},[_c('router-link',{class:_vm.b('logo'),attrs:{"to":{ name: 'MyRooms' }}},[_c('img',{attrs:{"src":'/img/logo-small.svg',"alt":"DЕЛИSPACE - Раздел для арендодателей"}})])],1),_c('div',{class:_vm.b('user', { small: _vm.isSmall })},[_c('div',{class:_vm.b('avatar', { small: _vm.isSmall })},[_c('img',{attrs:{"src":"/img/user.svg","alt":""}})]),(!_vm.isSmall)?[_c('div',{class:_vm.b('username')},[_vm._v(" "+_vm._s(_vm.currentUser.name)+" ")]),(_vm.currentUser.account || _vm.currentUser.account === 0)?_c('div',{class:_vm.b('balance')},[_c('BaseIcon',{attrs:{"icon-name":"money","width":16,"height":16}}),_c('span',[_vm._v(_vm._s(_vm.toLocalCurrency(Number(_vm.currentUser.account)))+"р")]),(
            _vm.currentUser.billing_plan.next &&
            !_vm.currentUser.billing_plan.next.paid &&
            _vm.currentUser.billing_plan.next.pay_before &&
            1 * _vm.currentUser.account < _vm.currentUser.billing_plan.next.sum
          )?_c('BaseIcon',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"content":((new Date(
            _vm.currentUser.billing_plan.next.pay_before
          ).toLocaleDateString('ru-RU')) + " Произойдет списание " + (_vm.currentUser.billing_plan.next.sum) + " руб. на продление услуг размещения. Пополните счет еще на " + (_vm.currentUser.billing_plan.next.sum - 1 * _vm.currentUser.account) + " руб."),"icon-name":"warning","width":20,"height":20}}):(
            !_vm.currentUser.billing_plan.current &&
            _vm.currentUser.billing_plan.next &&
            !_vm.currentUser.billing_plan.next.paid &&
            1 * _vm.currentUser.account < _vm.currentUser.billing_plan.next.sum
          )?_c('BaseIcon',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"content":("У вас нет активного тарифа. Для продолжения размещения пополните счет еще на " + (_vm.currentUser.billing_plan.next.sum - 1 * _vm.currentUser.account) + " руб."),"icon-name":"warning","width":20,"height":20}}):_vm._e()],1):_vm._e(),_c('div',{class:_vm.b('btn')},[_c('BaseButton',{attrs:{"color":"green"},on:{"click":function($event){_vm.showDepositInvoice = true}}},[_vm._v("Пополнить счет")])],1)]:_vm._e()],2),(_vm.breakpoints.width > 700 || _vm.openMobileMenu)?_c('Menu',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],attrs:{"small":_vm.breakpoints.width <= 1366 && _vm.breakpoints.width > 700}}):_vm._e(),(_vm.breakpoints.width > 1366)?_c('Hint',{class:_vm.b('hint')}):_c('TippyComponent',{class:_vm.b('tippy', { compact: _vm.breakpoints.width <= 700 }),attrs:{"interactive":"","theme":"dropdown","trigger":"click","animation":"fade","placement":_vm.breakpoints.width < 768 ? 'bottom-end' : 'left-end',"distance":20,"arrow":false,"animation-fill":false,"duration":0},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('BaseButton',{class:_vm.b('hint-button', { compact: _vm.breakpoints.width <= 700 }),attrs:{"color":"gray"},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('BaseIcon',{class:_vm.b('icon'),attrs:{"icon-name":"telegram-plane-brands","fill":'white',"width":20,"height":20}})],1)]},proxy:true}])},[_c('Hint',{class:_vm.b('tippy-hint')})],1),(_vm.breakpoints.width <= 700)?_c('Toggler',{attrs:{"id":"mobileMenuToggler"},model:{value:(_vm.openMobileMenu),callback:function ($$v) {_vm.openMobileMenu=$$v},expression:"openMobileMenu"}}):_vm._e(),(_vm.showDepositInvoice)?_c('DepositInvoice',{on:{"close":function($event){_vm.showDepositInvoice = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }